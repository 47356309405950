import {SystemColour, systemColours} from '../../../craft/utils/colour.types'

// TODO: adopt dato strict mode to get proper type definitions in the schema
// https://www.datocms.com/blog/introducing-strict-mode-for-graphql-cda-get-the-best-typescript-dx
export function validateDatoCmsTypes<Model>(entry: Model): Required<Model> {
  // dato graphql api has every field optional, even if they're required in the data model
  // this makes the casts and bang usage below quite unsafe but acceptable because it's enforced at the CMS level
  return entry as Required<Model>
}

export function sanitizePathUrl(path: string): string {
  //remove leading and trailing slashes
  return path.replace(/^\/|\/$/g, '')
}

export function parseSystemColour(colour: string): SystemColour | undefined {
  if (!colour) return

  const systemColour: SystemColour | undefined = systemColours.find(c => c === colour.toLowerCase())

  if (!systemColour) {
    throw new RangeError(`System colour could not be found for ${colour}`)
  }

  return systemColour
}
